<template>
  <div>
    <label :for="id">{{ label }}</label>
    <input 
      type="file" 
      :id="id" 
      @change="handleFileChange" 
      class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-100 focus:outline-none"
    />
    <p v-if="uploadMessage" class="mt-2 text-sm text-gray-600">{{ uploadMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    uploadMessage: String,
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0]; // Obtém o primeiro arquivo selecionado
      this.$emit('input', file); // Emite o arquivo selecionado
    }
  }
}
</script>
