<template>
  <div class="m-4">
    <div class="">
      <h3 class="text-[40px] font-extrabold text-left py-2 uppercase">
        {{ nome }} {{ sobrenome }}
      </h3>
      <CustomRouterLinkButton :to="{ name: 'driver_feedback' }" buttonText="+ 500 Feedbacks recebidos"
        :style="'transparent'" class="border-none text-red-500 font-bold" />
    </div>

    <div class="flex justify-start my-4">
      <CustomRouterLinkButton :to="{ name: 'driver_edit' }" buttonText="Editar Perfil" :style="'transparent'" />
      <CustomButton :style="'orange'" label="Carregar saldo" @click="showChargeModal = true" class="ml-6" />

    </div>

    <div class="grid grid-cols-3 gap-4 my-4">
      <DarkCard :title="rideData.completed_rides" subtitle="Pedidos atendidos"></DarkCard>
      <DarkCard :title="rideData.earning" subtitle="Coletados"></DarkCard>
      <DarkCard :title="rideData.balance" subtitle="Saldo do disponível"></DarkCard>
    </div>
    <div class="grid grid-cols-2 gap-4 my-12">
      <div v-if="isLoadingPersonalData" class="flex justify-center items-center py-10">
        <InnerPreLoaderComponent />
      </div>
      <div v-else class="">
        <PersonalInfo :data="personalData" :showBI="false" editRoute="driver_edit" />
      </div>
      <div class="w-full bg-white border border-gray-200 rounded-lg shadow p-5">
        <div class="flex justify-between">
          <span class="text-3xl font-bold text-gray-900 uppercase">Sobre a Viatura</span>
          <button @click="vehicle_modal = true">
            <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path
                d="M4.5 17.207V19a.5.5 0 00.5.5h1.793a.5.5 0 00.353-.146l8.5-8.5-2.5-2.5-8.5 8.5a.5.5 0 00-.146.353zM15.09 6.41l2.5 2.5 1.203-1.203a1 1 0 000-1.414l-1.086-1.086a1 1 0 00-1.414 0L15.09 6.41z">
              </path>
            </svg>
          </button>
        </div>
        <div v-if="isLoadingCarData" class="flex justify-center items-center py-10">
          <InnerPreLoaderComponent />
        </div>
        <div v-else>

          <div v-if="carro.length === 0" class="text-center py-10">
            <p class="text-xl text-gray-500">Não há carros associados a este motorista.</p>
          </div>

          <div v-else
            class="flex items-center justify-between bg-gray-50 border-gray-400 rounded-lg mb-2 grid grid-cols-3">

            <div class="col-span-2">
              <ProfileImage :imageUrl="imageUrl" altText="Extra large avatar" class="w-fit h-auto" />
            </div>

            <div class="my-5">
              <div class="my-5">
                <p class="text-wewa-orange font-bold mb-2">
                  {{ carro.make }}
                </p>
                <div :key="chave">
                  <p>{{ carro.model }} </p>
                  <p>{{ carro.color }}</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>


      <!-- reservado para graficos -->
      <div class="flex items-center justify-center rounded h-28 bg-white border border-gray-200 shadow">
        <p class="text-2xl text-gray-400 dark:text-gray-500">
          <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 18 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 1v16M1 9h16" />
          </svg>
        </p>
      </div>
      <div class="flex items-center justify-center rounded h-28 bg-white border border-gray-200 shadow">
        <p class="text-2xl text-gray-400 dark:text-gray-500">
          <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 18 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 1v16M1 9h16" />
          </svg>
        </p>
      </div>
      <!-- fim de reserva para graficos -->
    </div>

    <div class="">
      <TableComponent :columns="colunas" :rows="dados" :filters="filters" :filterKey="filterKey" :idKey="idKey"
        :urlPrefix="urlPrefix" />
    </div>
  </div>

  <modal :show="showChargeModal" modalWidth="600px" @close="showChargeModal = false">

    <div class="text-left">
      <h2 class="text-xl font-bold mb-4 uppercase">Carregar Saldo</h2>
      <p class="mb-4 font-bold">
        Preencha os campos abaixo devidamenta para fazer o carregamento do saldo
        da conta deste motorista.
      </p>

      <div class="my-5">
        <p><span class="font-bold">ID do motorista: </span>{{ idMotorista }}</p>
        <p><span class="font-bold">Nome do motorista: </span>{{ nome }} {{ sobrenome }}</p>
        <p>
          <span class="font-bold">Saldo disponível: 100M Kz</span>
        </p>
      </div>
   <form @submit.prevent="chargeCar">
      <!-- <div>
        <InputFieldComponent label="Quantidade a carregar" placeholder="1"  v-model="valueCharge" required
          type="number"  />
      </div> -->
       <div>
          <label for="first_name" class="block mb-2 text-sm font-bold text-gray-900">Valor</label>
          <input
            class="bg-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-gray-300 block w-full p-2.5"
            placeholder="1000" required id="first_name" type="number" v-model="valueCharge" />
        </div>

      <div class="">
            <input type="file" class="custom-file-input" id="customFile" ref="file" @change="handleFileObject()">
                    <label class="custom-file-label text-left" for="customFile">{{ thumbName }}</label>
      </div>
      <div class="flex justify-end">
        <CustomButton :style="'orange'" label="Carregar saldo"  />
      </div>
    </form>
    </div>
  </modal>

  <modal :show="vehicle_modal" modalWidth="700px" @close="vehicle_modal = false">
    <div class="text-left">
      <div v-if="carro.length === 0">
            <TitleComponent title="Veículos registrados "
        subtitle="Não há carros associados a este motorista. Clique em novo para adicionar. " textButton="+ Novo"
        showButton="true" :routerLink="{ name: 'vehicle_register' }" />
          </div>
          <div v-else>
      <TitleComponent title="Veículos registrados "
        subtitle="Todos os seus veículos registrados na plataforma estão listados baixo." textButton="+ Novo"
        showButton="false" :routerLink="{ name: 'vehicle_register' }" />

      <div class="flex items-center justify-between bg-gray-50 border-gray-400 rounded-lg mb-2">
        <ProfileImage :imageUrl="imageUrl" altText="Extra large avatar" class="w-1/3 h-auto" />

        <car-info :marca="carro.make"></car-info>

        <div class="font-semibold text-base text-black flex flex-nowrap items-center mt-5 ml-5">
          <router-link to="" class="hover:underline pr-1">Ver</router-link>
          <router-link :to="{ name: 'vehicle_edit' }" class="hover:underline pr-1">Atualizar</router-link>

          <button class="text-red-600 hover:underline pr-1" @click="confirmDeleteCar(carro.id)">
            Eliminar
          </button>
        </div>
      </div>
    </div>
    </div>
  </modal>

  <modal :show="showErrorModal" @close="showErrorModal = false">
    <div class="text-left">
      <h2 class="text-xl font-bold mb-4 uppercase">{{ errorMessage.code }}</h2>
      <p class="mb-4">{{ errorMessage.message }}</p>
      <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
    </div>
  </modal>
</template>
<script>

import PersonalInfo from "@/components/profile/CardInfo.vue";
import CustomRouterLinkButton from "@/components/CustomRouterLinkButton.vue";
import DarkCard from "@/components/DarkCardComponent.vue";
import TableComponent from "@/components/TableComponent.vue";

import modal from "@/components/modal/Modal.vue";
import CustomButton from "@/components/CustomButton";
// import InputFieldComponent from "@/components/form/InputFieldComponent.vue";
// import FileUploadComponent from "@/components/form/FileUploadComponent.vue";
import ProfileImage from "@/components/profile/ProfileImage.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import CarInfo from "@/components/CarInfoComponent.vue";
import InnerPreLoaderComponent from "@/components/preLoader/InnerPreLoader.vue";
import axios from "axios";

export default {
  components: {
    PersonalInfo,
    CustomRouterLinkButton,
    DarkCard,
    TableComponent,
    modal,
    CustomButton,
    // InputFieldComponent,
    // FileUploadComponent,
    ProfileImage,
    TitleComponent,
    CarInfo,
    InnerPreLoaderComponent
  },
  onFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    console.log(file); // Verifique se isso retorna um objeto de arquivo
    this.voucher = file;
  }
},
  data() {
    return {
      imageUrl: require("../../assets/img/avatar/car_avatar.png"),
      showChargeModal: false,
      vehicle_modal: false,
      valueCharge: 0,
      voucher:null,
      carro: [],
      personalData: {
        Email: "",
        "Nº de bilhete": "",
        "Nº de telefone": "",
        "Nº da carta de condução": "",
        "Validade da carta": "",
        "Nº de passaporte": "",
        Gênero: "",
        // Província: 'Luanda (Statico)',
        // Município: 'Cazenga (Statico)',
        // 'Bilhete de identidade': 'Ver arquivo (Statico)',
        // 'Carta de condução': 'Ver arquivo (Statico)',
        Nacionalidade: "",
        "Estado civil": "",
      },
      colunas: ["ID", "Produto", "Preço", "Status"],
      dados: [
        {
          id: 1,
          Nome: 'Apple MacBook Pro 17"',
          "Nº do bilhete": "Sliver",
          "Nº de telefone": "Laptop",
          user: true,
        },
        {
          id: 2,
          Nome: "Microsoft Surface Pro",
          "Nº do bilhete": "White",
          "Nº de telefone": "Laptop PC",
          driver: true,
        },
        {
          id: 3,
          Nome: "Magic Mouse 2",
          "Nº do bilhete": "Black",
          "Nº de telefone": "Accessories",
          passenger: true,
        },
      ],
      filters: [
        { texto: "Corridas", valor: "user" },
        { texto: "Delivery", valor: "driver" },
      ],
      nome: "A carregar...",
      sobrenome: "",
      idMotorista: "",
      currentDate: this.getCurrentDate(),
     
      isLoadingPernsonalData: false,
      isLoadingCarData: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false,
      rideData: {
        balance: "",
        completed_rides: "",
        earning: ""
      },
    };
  },
  methods: {
    async driverDetails() {
      this.isLoadingPersonalData = true;
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          await this.$router.push("/");
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const driverId = this.$route.params.id;

        const response = await axios.get(`drivers/${driverId}`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          this.details = response.data.data;
          this.personalData.Email = this.details.user.email;
          this.personalData["Nº de bilhete"] =
            this.details.user.profile.identity_card_number;
          this.personalData["Nº de telefone"] = this.details.user.phone_number;
          this.personalData["Nº da carta de condução"] =
            this.details.driving_license_number;
          this.personalData["Nº de passaporte"] =
            this.details.user.profile.passport_number;
          this.personalData.Gênero = this.details.user.profile.gender;
          this.personalData.Nacionalidade =
            this.details.user.profile.nationality;
          this.personalData["Estado civil"] =
            this.details.user.profile.marital_status;
          this.personalData["Validade da carta"] =
            this.details.driving_license_validity;

          this.nome = this.details.user.first_name;
          this.sobrenome = this.details.user.last_name;
          this.idMotorista = this.details.id
        } else {
          this.errorMessage.code = "Erro ao carregar detalhes";
          this.errorMessage.message = "Algo deu errado ao carregar os detalhes do motorista. Tente novamente mais tarde.";
          this.showErrorModal = true;
        }


      } catch (error) {
        console.log("Erro ao exibir detalhe", error);
        this.handleError(error);
      } finally {
        this.isLoadingPersonalData = false;
      }
    },

    async rides_data() {
      try {
        const token = localStorage.getItem("token");

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const driverId = this.$route.params.id;

        const response = await axios.get(`rides-data-driver/${driverId}`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          this.ride_data = response.data.data;
          this.rideData.completed_rides = this.ride_data.completed_rides;
          this.rideData.earning = this.ride_data.earning;
          this.rideData.balance = this.ride_data.balance;
        }
      } catch (error) {
        console.log("Erro", error);
        this.handleError(error);
      }
    },

    async listCars() {
      this.isLoadingCarData = true;
      try {
        const token = localStorage.getItem("token");

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const driverId = this.$route.params.id;

        const response = await axios.get(`cars/${driverId}`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          this.carro = response.data.data;
        }
      } catch (error) {
        console.log("Erro ao listar os carros", error);
        // this.handleError(error);
      } finally {
        this.isLoadingCarData = false;
      }
    },
    async chargeCar() {
      try {
        const token = localStorage.getItem("token");

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        
        };

        const driverId = this.$route.params.id;

    // Criando um objeto FormData
    const formData = new FormData();
    formData.append('user_id', driverId);
    formData.append('date', this.currentDate);
    formData.append('value', this.valueCharge);
    formData.append('type', 'commission');
    formData.append('voucher', this.thumbnail)
    // formData.append('voucher', this.voucher);
    console.log(this.valueCharge, this.thumbnail);
    
    // Adicionando o voucher (arquivo)
    // formData.append('voucher', this.voucher); // Certifique-se de que "this.voucher" seja o arquivo correto

    const response = await axios.post(`driver-charges`, formData, {
      headers: customHeaderParams,
    });


        if (response.status === 200 && response.data.success === true) {
          // console.log("Saldo carregado com sucesso");
          this.errorMessage.code = "Saldo Carregado com sucesso";
          this.errorMessage.message = "Saldo carregado com sucesso";
          this.showErrorModal = true;
          // console.log(chargData)
          this.showChargeModal = false
        } else {
          this.errorMessage.code = "Erro ao carregar saldo";
          this.errorMessage.message = "Algo deu errado ao carregar o saldo do motorista. Tente novamente mais tarde.";
          this.showErrorModal = true;
        }
      } catch (error) {
        console.log("Erro ao carregar o saldo", error);
        this.handleError(error);
      }
    },
      handleFileObject() {
        this.thumbnail = this.$refs.file.files[0]
        this.thumbName = this.thumbnail.name
      },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    handleError(error) {
      let validationErrors;

      switch (error.response ? error.response.status : null) {
        case 422:
          validationErrors = error.response.data.error.errors;
          if (validationErrors) {
            const firstErrorKey = Object.keys(validationErrors)[0];
            this.errorMessage.code = "Erro de Validação";
            this.errorMessage.message = validationErrors[firstErrorKey][0];
          } else {
            this.errorMessage.code = "Erro de Validação";
            this.errorMessage.message = "Erro de validação desconhecido.";
          }
          break;
        case 401:
          this.errorMessage.code = "Token Inválido ou Expirado";
          this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
          break;
        case 403:
          this.errorMessage.code = "Acesso negado";
          this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
          break;
        case 404:
          this.errorMessage.code = "Recurso não encontrado";
          this.errorMessage.message = "O recurso solicitado não foi encontrado.";
          break;
        default:
          if (error.response) {
            this.errorMessage.code = "Erro do Servidor";
            this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
          } else if (error.request) {
            this.errorMessage.code = "Sem Resposta do Servidor";
            this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
          } else {
            this.errorMessage.code = "Erro na Requisição";
            this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
          }
          break;
      }
      this.showErrorModal = true;
    }
  },
  mounted() {
    this.driverDetails();
    this.listCars();
    this.rides_data();
  },
};
</script>
<style></style>
